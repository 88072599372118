<template>
  <main class="main">
    <div class="container fluid">
      <section class="row registration my-0">
        <div class="col-lg-6 col-md-12 registration__left">
          <h2 class="registration__title">Payment information</h2>
          <form class="registration__form" autocomplete="off">
            <div class="registration__form-input">
              <input
                v-model="paymentInfo.firstName"
                type="text"
                placeholder="Full name"
                class="mr-4"
              />
              <input
                v-model="paymentInfo.lastName"
                type="text"
                placeholder="Last name *"
              />
            </div>
            <input
              v-model="paymentInfo.phone"
              class="col-12 registration__input"
              :class="{registration__input_error: errorMessage}"
              type="tel"
              placeholder="Number with full format"
              @input="validatePhone"
            />
            <input
              v-model="paymentInfo.email"
              class="col-12 registration__input"
              type="email"
              placeholder="Email address *"
            />
            <div class="registration__checkbox">
              <input type="checkbox" id="check" v-model="deliveryEnabled" />
              <label for="check" class="mb-0">Enter delivery address</label>
            </div>
            <div v-if="deliveryEnabled" class="registration__delivery-info">
              <label for="country" class="d-flex flex-column mb-2">
                <p class="mb-1">
                  <span class="delivery-info__input-label">Country</span>
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <!-- <input
                  v-model="deliveryInfo.country"
                  id="country"
                  type="text"
                  placeholder="Страна"
                /> -->
                <v-autocomplete
                  id="country"
                  item-value="id"
                  item-text="country"
                  :items="countriesList"
                  class="ma-0 pa-0"
                  @change="getDeliveryPrice()"
                  v-model="deliveryInfo.country"
                ></v-autocomplete>
              </label>
              <label for="address" class="d-flex flex-column mb-2">
                <p class="mb-1">
                  <span class="delivery-info__input-label">Address</span>
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <input
                  v-model="deliveryInfo.address"
                  id="address"
                  type="text"
                  placeholder="House number and street name"
                />
              </label>
              <!-- <label for="living-area" class="d-flex flex-column mb-2">
                <p class="mb-1">
                  <span class="delivery-info__input-label">Address 2</span>
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <input
                  v-model="deliveryInfo.livingArea"
                  id="living-area"
                  type="text"
                />
              </label> -->
              <label for="district" class="d-flex flex-column mb-2">
                <p class="mb-1">
                  <span class="delivery-info__input-label">Area/region</span>
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <input
                  v-model="deliveryInfo.district"
                  id="district"
                  type="text"
                />
              </label>
              <label for="mail-index" class="d-flex flex-column mb-2">
                <p class="mb-1">
                  <span class="delivery-info__input-label">Postal code</span>
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <input
                  v-model="deliveryInfo.mailIndex"
                  id="mail-index"
                  type="text"
                />
              </label>
              <label
                v-if="additionalField"
                for="inn"
                class="d-flex flex-column mb-2"
              >
                <p class="mb-1">
                  <span class="delivery-info__input-label">INN</span>
                  <span class="delivery-info__input-required-sign">*</span>
                </p>
                <!-- v-model="deliveryInfo.inn" -->
                <input
                  id="inn"
                  type="number"
                  maxlength="12"
                  v-model="deliveryInfo.inn"
                  @input="innInput"
                />
              </label>
            </div>
          </form>
        </div>
        <div class="col-lg-6 col-md-12 registration__right">
          <h2 class="registration__title">Your order</h2>
          <div class="registration__payment">
            <h3>If you have a coupon code, please apply it below</h3>
            <input
              class="payment__promocode"
              type="text"
              placeholder="Have a promocode / dicount card number?"
            />
            <button class="payment__btn">Apply coupon</button>
            <v-expansion-panels class="mb-1" flat>
              <v-expansion-panel>
                <v-expansion-panel-header class="payment__product mb-0">
                  <span>Product</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <template v-if="isSpecialProduct">
                    <div
                        v-for="(item, i) in order"
                        :key="i"
                        class="payment__goods px-0"
                    >
                    <span>{{ item.product.name.ru }} × {{ item.count }}</span>
                    <span class="price">{{ item.product.sale_cost }} KGS</span>
                    </div>
                  </template>
                  <template v-else>
                    <div
                        v-for="item in order"
                        :key="item.cartId"
                        class="payment__goods px-0"
                    >
                    <span
                    >{{ item?.product.name.ru }} ×
                      {{ item?.size?.count }}</span
                    >
                      <span class="price">
                      {{
                          Math.ceil(
                              item?.size?.price?.view -
                              (item?.size?.price?.view *
                                  item?.size?.price?.discount?.discounts[0]
                                      ?.value) /
                              100
                          )
                        }}
                    </span>
                    </div>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="payment__total-wrapper">
              <!-- <div class="payment__subtotal">
                <span>Subtotal</span>
                <span class="subtotal-price">$80</span>
              </div>
              <div class="payment__shipping">
                <span>Shipping</span>
                <span class="shipping-price">$20</span>
              </div> -->
            </div>
            <div class="payment__total">
              <span>Delivery</span>
              <span class="total notranslate">
                <span class="total notranslate"
                  >{{ Number(deliveryPriceView) }}
                </span>
                <span class="total">
                  {{ isSpecialProduct ? 'KGS' : order[0]?.size?.currency.currency.currency }}
                </span>
              </span>
            </div>
            <div class="payment__total">
              <span>Total</span>
              <span class="total notranslate">
                <span class="total notranslate">{{ subtotal }}</span>
                <span class="total">
                  {{ isSpecialProduct ? 'KGS' : order[0]?.size?.currency.currency.currency }}
                </span>
              </span>
            </div>
            <div class="payment__method">
              <div
                v-for="item in paymentMethods"
                :key="item.value"
                class="payment_option"
                :class="
                  selectedPayment === item.value ? 'payment_option_active' : ''
                "
                @click="selectedPayment = item.value"
              >
                <img :src="item.image" :class="item.class" :alt="getImageName(item.image)" :title="getImageName(item.image)" 
                />
              </div>
            </div>
            <div class="payment__note">
              <p>Payment is made through the ePay QAZKOM system</p>
            </div>
            <p class="payment__text">
              By placing an order, you accept our rules, <a href="/pages/Privacy-Policy">privacy policy</a> and
              <a href="/pages/returns-exchanges"> return policy</a>. You also agree that TUMAR will store some of your
              data to improve the quality of service the next time you shop on
              our site.
            </p>
            <button
              :class="{ valid: isDeliveryInfoValid && isPaymentInfoValid && !errorMessage.length }"
              @click="onOrder"
              class="place-order-btn"
            >
              Place order
            </button>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import axios from '@/core/services/api.service'
import { getImageName } from '@/utils/utils'

import { createNamespacedHelpers } from 'vuex'

const { mapState: mapOrderState, mapActions: mapOrderActions } =
  createNamespacedHelpers('orderHistory')
const { mapState: mapCountryState, mapActions: mapCountryActions } =
  createNamespacedHelpers('country')
const { mapState: State_Artisan } = createNamespacedHelpers('artisan')

export default {
  name: 'Registration',
  data() {
    return {
      paymentInfo: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
      },
      deliveryEnabled: false,
      deliveryInfo: {
        country: 236,
        address: '',
        // livingArea: '',
        district: '',
        mailIndex: '',
        inn: '',
      },
      additionalField: false,
      selectedPayment: 'kkb',
      order: [],
      errorMessage: ""
    }
  },
  computed: {
    ...State_Artisan(['artisan']),
    ...mapOrderState(['deliveryPrice']),
    ...mapCountryState(['countriesList']),
    deliveryPriceView() {
      return this.deliveryPrice.delivery_view
    },
    isSpecialProduct () {
      return this.order.find(item => item.isSpecialProduct)?.isSpecialProduct
    },
    isPaymentInfoValid() {
      const isEmpty = Object.values(this.paymentInfo).every(
        (value) => value !== ''
      )
      if (isEmpty) {
        return true
      } else {
        return false
      }
    },
    total() {
      return this.order.reduce((acc, curr) => {
        return (acc += curr?.size?.count * curr?.size?.price?.view)
      }, 0)
    },
    paymentMethods() {
      return [
        // временно убрали пейпал
        // {
        //   label: 'Pay Pal',
        //   value: 'paypal',
        //   image: '/img/paypal.png',
        //   class: 'paypal_img',
        // },
        {
          label: 'Kyrgyz Commerce Bank',
          value: 'kkb',
          image: '/img/visa.png',
          class: 'kkb_img',
        },
      ]
    },
    subtotal() {
      if (this.isSpecialProduct) {
        const price = this.order.reduce((acc, curr) => {
          return (acc += curr.product.sale_cost * curr.count)
        }, 0)

        return price.toFixed(2)
      } else {
        const price = this.order.reduce((acc, curr) => {
          if (+curr?.size?.price.discount.view) {
            return (acc +=
                (curr?.size?.price?.view - curr?.size?.price?.discount?.view) *
                curr?.size?.count)
          } else {
            return (acc += curr?.size?.price?.view * curr?.size?.count)
          }
        }, 0)
        return price.toFixed(2)
      }
    },
    isDeliveryInfoValid() {
      const isFilledExceptInn = Object.entries(this.deliveryInfo).every(
        ([key, value]) => {
          return key === 'inn' || value !== ''
        }
      )
      if (isFilledExceptInn) {
        return true
      } else {
        return false
      }
    },
  },
  watch: {
    'deliveryInfo.country'(val) {
      if (val === 'Россия') {
        this.additionalField = true
      } else {
        this.additionalField = false
      }
    },
  },

  methods: {
    getImageName,

    ...mapOrderActions([
      'createRetailOrder',
      'createSpecialProductOrder',
      'fetchDeliveryPrice',
      'payByPaypal',
      'payByKKB',
    ]),
    ...mapCountryActions(['fetchCountriesList']),
    validatePhone() {
      const noLettersRegex = /^[^a-zA-Zа-яА-ЯёЁ]*$/;

      if (this.paymentInfo.phone.length === 0) {
        this.errorMessage = ""
      }

      if (!noLettersRegex.test(this.paymentInfo.phone)) {
        this.errorMessage = "Неверный формат номера"
      } else {
        this.errorMessage = ""
      }
    },
    getDeliveryPrice() {
      if (this.isSpecialProduct) {
        return
      }

      let result = {
        country_id: null,
        products: [],
      }
      result.country_id = this.deliveryInfo.country
      this.order.forEach((i) => {
        result.products.push({
          product_id: i?.size?.id,
          amount: i?.size?.count,
        })
      })
      this.fetchDeliveryPrice(result)
    },
    innInput(e) {
      if (e.target.value.length >= 11) {
        this.deliveryInfo.inn = e.target.value.substring(0, 12)
        return
      }
      this.deliveryInfo.inn = e.target.value
    },
    async onOrder() {
      if (this.errorMessage || this.paymentInfo.phone.length === 0) {
        return
      }
      // if (!this.isDeliveryInfoValid && !this.isPaymentInfoValid) {
      //   return
      // }
      localStorage.setItem('goBackLogin', JSON.stringify(this.$route.path))
      try {
        let payload = {}
        let userInfo = {}
        let products = []
        let body = {}
        userInfo.first_name = this.paymentInfo.firstName
        userInfo.last_name = this.paymentInfo.lastName
        userInfo.phone = this.paymentInfo.phone
        userInfo.email = this.paymentInfo.email
        userInfo.country_id = this.deliveryInfo.country
        userInfo.address = this.deliveryInfo.address
        userInfo.locality = 'locality'
        userInfo.zip_code = this.deliveryInfo.mailIndex
        userInfo.wine_region = 'wine_region'
        userInfo.tax_number = 'tax_number'

        // payload for order special good
        payload.fname = this.paymentInfo.firstName
        payload.lname = this.paymentInfo.lastName
        payload.phone = this.paymentInfo.phone
        payload.email = this.paymentInfo.email
        payload.artisan_id = this.artisan.id
        payload.country_id = this.deliveryInfo.country
        payload.address = this.deliveryInfo.address
        // payload.settlement = this.deliveryInfo.livingArea
        payload.region = this.deliveryInfo.district
        payload.zip_code = this.deliveryInfo.mailIndex

        this.order.forEach((item) => {
          if (this.isSpecialProduct) {
            products.push({
              id: item.product.id,
              color_id: item.color.id,
              quantity: item.count,
              dimensions: [item.size],
              thickness: [item.thickness]
            })
          } else {
            products.push({
              product_id: item?.size?.id,
              amount: item?.size?.count,
              price_view: item?.size?.price?.view,
              price_raw: item?.size?.price?.raw,
              currency_id: item?.size?.currency?.currency?.id,
              discount: item?.size?.price?.discount,
            })
          }
        })

        payload['products'] = products

        if (this.isSpecialProduct) {
          const res = await this.createSpecialProductOrder(payload)

          const uuid = res.data.order_uuid
          const resHalPrice = await axios.post(`/specialgoods/order/${uuid}/halfPriceCheckout`)
          window.open(resHalPrice.data.redirect_url, '_blank')
        } else {
          const response = await this.createRetailOrder({
            userInfo,
            products,
            delivery: this.deliveryPrice,
          })
          if (response.status === 200) {
            if (this.selectedPayment === 'kkb') {
              const res = await this.payByKKB({
                order_id: response?.data?.order?.id,
              })
              if (res.status === 200) {
                window.open(res?.data?.redirect_url, '_blank')
              }
            } else if (this.selectedPayment === 'paypal') {
              const res = await this.payByPaypal({
                order_id: response?.data?.order?.id,
              })
              if (res.status === 200) {
                window.open(res?.data?.redirect_url, '_blank')
              }
            }
          }
        }
      } catch (error) {
        // console.error(error)
      }
    },
  },
  async mounted() {
    await this.fetchCountriesList()
    if (this.$route.params.order?.length) {
      localStorage.setItem('retailOrder', JSON.stringify([]))
      this.order = this.$route.params.order
      localStorage.setItem(
        'retailOrder',
        JSON.stringify(this.$route.params.order)
      )
    } else {
      const storedOrder = localStorage.getItem('retailOrder')
      this.order = storedOrder ? JSON.parse(storedOrder) : []
    }
  },
}
</script>

<style lang="scss" scoped>
.payment__text a {
    font-size: 12px!important;
    text-decoration: underline!important;
}
.main {
  border: none;
}
.registration__input {
  &_error {
    border-color: red;
  }
}
.registration__delivery-info {
  select {
    position: relative;
    &:before {
      position: absolute;
      content: '^';
      right: 20px;
      top: 0;
    }
    // appearance: auto;
    padding-right: 30px;
  }
}
.payment_option {
  padding: 10px 15px;
  background: #f6f6f6;
  margin-right: 40px;
  border-radius: 5px;
  cursor: pointer;
}
.payment_option_active {
  background: #cfebcc;
}
.delivery-info {
  &__input-label {
    // font-family: 'Alegreya Sans';
    font-weight: 500;
    color: #423f3d;
  }
  &__input-required-sign {
    color: #ea6c6c;
  }
}
.place-order-btn {
  cursor: default;
  &.valid {
    background-color: #cfebcc;
    color: #666666;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.registration {
  &__delivery-info {
    #country {
      border: none;
    }
    .v-select__slot {
      border: 0.7px solid #dcdcdc;
      border-radius: 2px;
    }
  }
  .v-expansion-panel-content__wrap {
    padding: 0 15px 14px 27px !important;
  }
  .v-text-field > .v-input__control > .v-input__slot:before,
  .v-text-field > .v-input__control > .v-input__slot:after {
    content: none;
  }
  .v-text-field > .v-input__control > .v-input__slot:after {
    content: none;
  }
  .v-input input {
    max-height: unset;
  }
  .v-text-field input {
    padding: 10px 30px;
  }
  .v-select:not(.v-text-field--single-line):not(.v-text-field--outlined)
    .v-select__slot
    > input {
    font-size: 14px;
    line-height: 15px;
  }
}
.v-autocomplete__content .v-select-list.v-list.v-sheet {
  background-color: #ffffff;
  border: 0.7px solid #dcdcdc;
}
img.paypal_img {
  width: 85px;
}
img.kkb_img {
  width: 42px;
}
</style>
